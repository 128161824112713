import { FaTrash } from "react-icons/fa";
import Hover from './hovercomponent';
import _ from 'lodash';
import NumberFormatComp from '@Components/Common/NumberFormatComp';
import { UpdateSmartShip } from '@Components/Common/CommonMethods';

const Product = ({ subscribeAlready, smartShipCheck, minCartAutoshipCheck, Cart, setcoupon, cartdata, deleteproduct, getMiniCartProduct, logintoken,
  setCartData, Add, Sub, productQtyError, }) => {
  //check product is selected to autoship or normal
  const AUTOSHIP_PRICE = +Cart?.product?.autoship_cost_price;

  return (
    <tr>
      <td>
        <div className="cart-image">
          {Cart?.product?.product_images[0]?.image ? <img src={`${process.env.API_URL}${Cart?.product.product_images[0]?.image}`} /> : <img src="/images/no-image.jpg" />}
        </div>
      </td>

      <td>
        <div className="cart-product-details">{Cart?.product?.name}</div>
        <span className="name_tableData" dangerouslySetInnerHTML={{ __html: Cart?.product?.benefits }}></span>
      </td>

      {!(subscribeAlready) &&
        <td className="" data-value="Frequency">
          {AUTOSHIP_PRICE !== 0 &&
            <UpdateSmartShip
              product_id={+Cart?.product?.id} cartId={+Cart?.id}
              setcoupon={setcoupon}
              quantity={Cart?.quantity} defualtAutoship={Cart?.is_autoship}
              token={logintoken} callbackFunction={(section, logintoken) => getMiniCartProduct(section, logintoken)} setCartData={setCartData}
            />
          }
        </td>
      }
      <td>
        {Cart?.qty_display ?
          <div className="main-qty-sec">
            <div className="box">
              <div id="qty">
                <button type="button"
                  name={`${Cart?.product?.id},${null}`}
                  id={Cart?.product?.id}
                  className="sub" value={Cart?.quantity} onClick={(e) => { Sub(e, Cart?.id, Cart?.is_autoship) }}>-</button>
                <input
                  name={`${Cart?.product?.id},${null}`}
                  type="text"
                  readOnly
                  value={Cart?.quantity}
                />
                <button type="button" name={`${Cart?.product?.id},${null}`} id={Cart?.product?.id}
                  data-value={Cart?.product?.quantity}
                  className="add"
                  value={Cart?.quantity}
                  onClick={(e) => Add(e, Cart?.id, Cart?.is_autoship)}>+</button>
              </div>
            </div>
          </div> : 1
        }

      </td>
      <td>
        <div className="cart-product-details">
          <div className="title">
            <NumberFormatComp value={Cart?.product?.cost_price * Cart?.quantity}
              render={value => <> {
                (Cart?.is_autoship === "False") ?
                  <>
                    {Cart?.product?.real_amount !== Cart?.product?.cost_price &&
                      Cart?.product?.real_amount !== "0.0" &&
                      <div className="discount-price-amount">
                        <NumberFormatComp value={Cart?.product?.real_amount * Cart?.quantity} />
                      </div>

                    }

                    <div>{value}</div>
                  </>
                  :
                  <div>
                    {`$${parseFloat(Cart?.product?.[`${(Cart?.is_autoship !== "False") ? "autoship_cost_price" : "cost_price"}`] * Cart?.quantity).toFixed(2)}`}
                  </div>
              }
              </>
              }
            />
          </div>

          {Cart?.is_autoship === "True" ?
            <div className="title">
              <Hover data="First order runs when your DNA reports are generated and every 30/45/60/90 days per your selection." />
            </div>
            :
            <div className="title">
              {/* <Hover data="Subscription is deactivated" />*/}
            </div>
          }
        </div>
        <div className="error-show">
          {productQtyError && <span style={{ color: "red" }}>{productQtyError}</span>}
        </div>

      </td>
      <td>
        <div className="pro-cart-table">
          <div className="cart-product-qty-del">
            <span className="only-rsponsive">Remove</span>
            <button className="dlt" ><FaTrash onClick={(e) => { deleteproduct(Cart?.product?.id, Cart?.variant?.id, Cart?.id, Cart?.is_autoship) }} /></button>
          </div>
        </div>
      </td>
    </tr>
  )
}
export default Product;