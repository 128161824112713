import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router'
import Router from 'next/router';
import api from '../api/Apis';
import '../../styles/globals.css';
import '../../styles/Home.module.css';
import "animate.css/animate.min.css";
import '../../styles/Responsive.module.css';
import Minicart from './[page]/cart/Minicart';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-phone-input-2/lib/style.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Cookie from 'js-cookie';
import Head from 'next/head';
import ReactGA from "react-ga4";
import dynamic from 'next/dynamic';
import axios from 'axios';
import _ from 'lodash';
import { isPercentageAssign, _getCartProducts, LinkAnchor, _getAllProduct, addToCartMethod } from '@Components/Common/CommonMethods';
import SiteAuth from '@Components/Common/SiteAuth';
import "react-datepicker/dist/react-datepicker.css";
import DialogPrivacyPolicy from 'privacy_policy/DialogComponent';
import moment from 'moment';
import Cookies from "js-cookie"
import DialogComponent from '@Components/Common/DialogComponent';
// import Script from "next/script";
import * as Sentry from "@sentry/react";

const NavBar = dynamic(() => import('../Components/NavBar'), { ssr: false });
const Footer = dynamic(() => import('../Components/Footer'), { ssr: false });

function MyApp({ Component, pageProps }) {
  const PassCode = "98765";
  const wrapperRef = useRef(null);
  const [reviewshow, setReviewShow] = useState();
  const [isLogin, setisLogin] = useState();
  const [accessSite, setAccessSite] = useState(false);
  const [isLoginToken, setisLoginToken] = useState();
  const [usernotifications, setUsernotification] = useState();
  const [isLogout, setisLogout] = useState();
  const [oldpath, setoldpath] = useState();
  const [counts, setcounts] = useState()
  const [updatecartdata, setupdatecartdata] = useState(false)
  var randomstring = require("randomstring");
  const router = useRouter()

  // const showHeader = router.pathname === "/[page]/signup" ? false : true;
  const [leadIdData, setLeadIddata] = useState()
  const [activeStep, setActiveStep] = React.useState(0);
  const [showminicart, setshowminicart] = useState(false)
  const [showloader, setshowloader] = useState(false)
  const [innerLoader, setInnerLoader] = useState(false)
  const [showprotectedpage, setShowProtectedPage] = useState(false)
  // const [openRefer, setOpenRefer] = useState(false);
  const [kitPopup, setKitPopup] = useState(false);

  const [customerCheck, setCustomerCheck] = useState(1);
  const [openForm, setOpenForm] = useState(false)
  const [profileData, setProfileData] = useState()
  const [subDomain, setSubDomain] = useState("");
  const [validReferralCheck, setValidReferralCheck] = useState(false);
  const [referralName, setReferralName] = useState();
  const [referralCompanyName, setReferralCompanyName] = useState();

  const [analyticsCode, setAnalyticsCode] = useState({ facebook: "837382719344418227664", google: "" })
  const [minCartAutoshipCheck, setMiniCartAutoshipCheck] = useState("False");

  const [smartShipCheck, setSmartShipCheck] = useState(false);
  const [cartdata, setCartData] = useState();
  const [promoterPackAvailability, setPromoterPackAvailibilty] = useState(false);
  const [loginError, setLoginError] = useState('')
  const [addNotify, setAddNotify] = useState('');
  const [correctRefferal, setCorrectRefferal] = React.useState({
    status: false,
    data: {}
  });
  const [getDetails, setDetail] = useState()
  const [releaseResult, setReleaseResult] = useState(false);
  const [dataReferer, setDataRefer] = useState();
  const [subscribeAlready, setSubscribeAlready] = useState(false);
  const [refferalFromUrl, setRefferalFromUrl] = React.useState(false);
  const [discount_amount1, setdiscount_amount] = useState();
  const [smartShipOnCart, setSmartShipOnCart] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [coupon, setcoupon] = useState();
  const [amountCalculations, setAmountCalculations] = useState();
  const [pageMsg, setPageMsg] = useState();
  const [userReportsCheck, setUserReportsCheck] = useState();
  const [landingPageData, setLandingPageData] = useState();
  const [oneTimeStatus, setOneTimeStatus] = useState(false);
  const [routerProductQuery, setRouterProductQuery] = useState('');
  const [routerKitQuery, setRouterKitQuery] = useState('');
  const [ordersData, setOrderData] = useState();
  const [productQtyError, setProductQtyError] = useState("");
  const code = router?.query?.token;
  const [minDate, setMinDate] = useState(new Date());
  useEffect(() => {
    const currentDate = new Date();
    const utcDate = new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate(),
      currentDate.getUTCHours(), currentDate.getUTCMinutes(), currentDate.getUTCSeconds()));
    function convertToCT(date) {
      const isDST = (date.getMonth() > 2 && date.getMonth() < 10) || (date.getMonth() === 2 && date.getDate() >= 8) || (date.getMonth() === 10 && date.getDate() <= 1);
      const ctOffset = isDST ? -5 * 60 : -6 * 60;
      return new Date(date.getTime() + ctOffset * 60000);
    }
    let ctDate = convertToCT(utcDate);
    ctDate.setHours(0, 0, 0, 0);
    if (ctDate.getDate() !== currentDate.getDate()) {
      ctDate = new Date(ctDate.setUTCDate(currentDate.getUTCDate()));
    }
    setMinDate(ctDate);

  }, []);

  useEffect(() => {
    const validPaths = ["/us/user/free_kit/", "/us/user/inoffice_dna_kits/"];
    if (validPaths?.includes(router?.asPath)) {
      localStorage?.removeItem("profileData");
    }
  }, [router?.asPath]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("coupon_name");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  function removeStorages() {
    localStorage.removeItem('Token');
    localStorage.removeItem('address_id');
    localStorage.removeItem('Login');
    localStorage.removeItem('logincredentials')
    localStorage.removeItem('packProduct');
    localStorage.removeItem('PromoterPack')
    localStorage.removeItem('profileDetail')
    localStorage.removeItem("consentForm");
    localStorage.removeItem('kitProduct');
    localStorage.removeItem('DISCOUNT_DATA');
    localStorage.removeItem("companyName");
    localStorage.removeItem("publicName");
    Cookies.remove('refferal-code');
    localStorage?.removeItem("barcode");
    localStorage.removeItem("consentFormCheckout");
    Cookies.remove('alternateid');
    // localStorage.removeItem('referral_code')
    localStorage.removeItem('shippingAddress')
    localStorage.removeItem('correctRefferal')
    Cookies.remove('redirectid');
    localStorage.removeItem('profileData');
    Cookies?.remove('discountkey');
    Cookies?.remove('apidiscount')
    Cookie.remove("sessionkey")
    setMiniCartAutoshipCheck("False");
    setSmartShipCheck(false);
    WishlistAllCart(null);
    setSubscribeAlready(false);
    setOneTimeStatus(false);
    setProfileData();
    setCustomerCheck(1)
    setReleaseResult(false);
    setUsernotification();
    setisLogin(false);
    setValidReferralCheck(false);
    setcounts()
    setCartData([])
    localStorage.removeItem('dna_product_paid');
    localStorage.removeItem('ancestryKey');
  }

  const handleLogout = async () => {
    const formdata = localStorage.getItem('Token') ? localStorage.getItem('Token') : '';
    await api.logoutApi(formdata).then(res => {
      if (res?.data?.code === 1) {
        removeStorages();
        router.push(`/`);
        Sentry.setUser(null);
      }
      if (res?.data?.code === 0) {


      }
    })
  }
  // qty counts in side cart
  useEffect(() => {
    if (router?.asPath === "/us/checkout/addresslist") {
      document.body.classList.remove('professional_retailer_form_popup');
    }
    let QTYCOUNTS = cartdata?.products?.reduce((prevValue, currentValue) => +prevValue + (+currentValue?.quantity), 0);
    setcounts(QTYCOUNTS)
  }, [counts, cartdata])


  useEffect(() => {
    if (router?.asPath === "/us/return-policy/") {
      const pdfUrl = "https://admin.snipnutrition.com/media/documents/snip_nutrigenomics_return_refund_policy.pdf";
      window.location.href = pdfUrl;
    }

  }, [router.asPath])


  const ARRAY_DATA = ["Premium CODE Report", "Healthy Body Mass Index (BMI)", "Heart Health", "Cognitive and Brain Function", "Detoxification", "Energy", "Gut/Microbiome", "Healthy Immune Response", "Healthy Inflammatory Response", "Methylation", "Mood",];



  //cart data
  async function WishlistAllCart(token) {
    // setshowloader(true)
    if (token) {
      await api.getAllCartProduct(token).then(res => {
        if (res?.status === 200 && res?.data?.code === 1) {
          const dataSss = localStorage.getItem('packProduct') ? JSON.parse(localStorage.getItem('packProduct')) : null
          let couuntValue = (dataSss !== null) ? +counts + 1 : +counts
          setcounts(couuntValue)
          // setcounts(res?.data?.products?.length)
          let data = _.map(res?.data.products, ({ product }) => +product?.id);
          setAddNotify(data);
          // setshowloader(false)
        }
      })
    }
  };


  useEffect(() => {
    if (router?.query?.redirectTo === "consent-form") {
      localStorage.setItem("consentForm", true);
    };
    if (router?.query?.redirectTo === "consentForm-checkout") {
      localStorage.setItem("consentFormCheckout", true);
    }
  }, [router])

  const LoginSubmit = async (data, section = "login", setError) => {
    await api.loginUser(data).then(res => {
      if (res?.data?.code == 1) {
        setShowProtectedPage(true);
        Sentry.setUser({ id: res?.data?.user_data?.userdetails?.[0]?.public_user_id, email: res?.data?.user_data?.email });
        if (cartdata?.products?.length > 0) {
          router.push('/us/checkout/addresslist')
        }
        else {
          if (router?.query?.redirectTo === "consent-form" || router?.query?.redirectTo === "consentForm-checkout") {
            setTimeout(() => {
              router?.push("/us/kit_form/?checkout");
            }, 2000);

            setTimeout(() => {
              localStorage.removeItem("consentForm");
            }, 4000);
          }
          else if (router?.query?.redirectTo) {
            router.push(router?.query?.redirectTo);
          } else {
            router.push('/us/user/dashboard/');
          }


        }

        if (res?.data?.user_data?.userdetails[0].privacy_policy === false) {
          setOpenPop(true)
        }

        setInnerLoader(false)
        // subdomainUserCheckFunction(res.data.token);
        localStorage.setItem('Login', true);
        localStorage.setItem('Token', res.data.token);
        getMiniCartProduct("onLoadData", res?.data?.token);
        WishlistAllCart(res?.data?.token);
        ProfileFunction(res?.data?.token);
        setCustomerCheck(res?.data?.user_data?.userdetails?.[0]?.qualified_as_rank);
        setisLogin(true);
      }

      if (res?.data?.code === 0 && res?.data?.message) {
        // if (section !== "login") {
        setError('email', { type: res?.data?.message, message: res?.data?.message });
        // }
        setInnerLoader(false)
      }
    })

  };


  //filter custom products//

  //mincart api
  async function getMiniCartProduct(section = "", logintoken, date) {
    const PARENT_REFFERAL = Cookie.get('refferal-code')?.length > 0 ? Cookie.get('refferal-code') : null;
    // setshowloader(true)
    await api.getAllCartProduct({ refferalCode: PARENT_REFFERAL, logintoken: logintoken }).then(res => {
      if (res?.status === 200) {
        if (res?.data?.code === 1) {
          const CARTDATA = _getCartProducts(res?.data);
          setCartData(CARTDATA);
          let productId;
          let variant_id;
          let product_qty;
          let is_autoship;
          let total = 0;
          let totalBounus = 0;
          let products = [];
          let weight = 0;
          // const CARTDATA = _getCartProducts(res?.data);

          let amount = 0;
          let NORMAL_AMOUNT = 0;
          _.forEach(_.filter(CARTDATA?.products, (val) => +val?.product?.autoship_cost_price !== 0), (row) => {
            const AMOUNT = row?.is_autoship === "True" ? +row?.product?.autoship_cost_price : +row?.product?.cost_price;
            amount += AMOUNT * +row?.quantity
          });
          _.forEach(_.filter(CARTDATA?.products, (val) => +val?.product?.autoship_cost_price === 0), (row) => {
            NORMAL_AMOUNT += row?.product?.cost_price * +row?.quantity
          })
          // setAutoShipAmount(amount);

          const AUTOSHIP_AVAILABLE = _.find(CARTDATA?.products, { is_autoship: "True" });
          if (AUTOSHIP_AVAILABLE) {
            setSmartShipOnCart(true);
          } else {
            setSmartShipOnCart(false);
          }
          CARTDATA?.products?.map((cart) => {
            weight += parseFloat(+cart?.quantity) * parseFloat(+cart?.product?.weight);
            if (cart.is_autoship === "True") {
              total = +(cart.product.autoship_cost_price * cart.quantity) + total;
              is_autoship = true

            }
            else {
              total = +(cart.product.cost_price * cart.quantity) + total;
              is_autoship = false

            }

            productId = cart?.product.id;
            product_qty = cart.quantity;
            variant_id = cart?.variant?.id ? cart.variant.id : '';

            products.push({
              product_id: parseInt(productId),
              quantity: parseInt(product_qty),
              sku: cart?.product?.sku,
              variant_id: parseInt(variant_id),
              is_autoship: is_autoship,
              shipping_frequency: is_autoship ? '30' : null,
              upcoming_shipping_date: date ? moment(date).format('yyyy-MM-DD') : JSON.parse(localStorage?.getItem("profileData"))?.user_data?.userdetails?.[0]?.test_report_release && cart.is_autoship === "True" ? moment(minDate).format('yyyy-MM-DD') : null
            })
          });
          const orderdata = {
            // "shipping_address_id": parseInt(billingaddress),
            "amount": parseFloat(total, 2),
            "subamount": parseFloat(total, 2),
            "bonus_value": totalBounus,
            "weight": weight,
            "shipping_amount": 0,
            "gross_total": parseFloat(total, 2),
            "coupon_id": null,
            "discount_amount": "00.00",
            "amount_paid": parseFloat(total, 2),
            "sku": "umb-20",
            "productsCount": _.sum(_.map(CARTDATA?.products, 'quantity')) ? 1 : 0,
            "products": products,
            "is_autoship": false,
            "autoshipTotalPrice": amount,
            "normalTotalPrice": NORMAL_AMOUNT
          }
          setReviewShow(orderdata);
          let data = _.map(res?.data?.products, ({ product }) => +product?.id);
          setAddNotify(data);
          setcounts(+counts);
          // setInnerLoader(false);
        }
        else {
          setSmartShipCheck(false);
          setCartData(res?.data);
          // if (res?.data?.message === "Cart is empty" && router.pathname.includes('checkout')) {
          //   router?.push('/')
          // }
          setcounts(0);
          // setInnerLoader(false);
          // router.push('/');
        }
      }
      else {
        setInnerLoader(false)
      }
    })
  };

  //referral code check function 
  async function subdomainUserCheckFunction(refferalCodes) {
    const formdata = localStorage.getItem('Token') ? localStorage.getItem('Token') : '';

    let status = false;
    try {
      const res = await api.subDomainCheckFunction({ referral_code: refferalCodes ? refferalCodes : Cookie.get('refferal-code') ? Cookie.get('refferal-code') : "" });
      if (res?.status === 200) {
        setValidReferralCheck(res?.data?.status);
        if (res?.data?.status !== true) {
          setCorrectRefferal({ status: res?.data?.status, data: {} });
          setSubDomain("");
          localStorage.setItem('popup_not_display', 'false');
          setOpenForm(true);
        }
        else {
          localStorage.setItem('popup_not_display', 'true');
          setOpenForm(false);
          const { user_details } = res?.data?.data;
          setCorrectRefferal({
            status: res?.data?.status,
            data: {
              name: user_details?.name,
              email: user_details?.email,
              phone: user_details?.phone
            }
          });

          setSubDomain(refferalCodes);
          // ReactGA.initialize(`${res?.data?.data?.google_analytic_key ? res?.data?.data?.google_analytic_key : ""}`, { testMode: true })

          setAnalyticsCode({
            facebook: res?.data?.data?.facebook_analytic_key ? res?.data?.data?.facebook_analytic_key : "837382719344418227664",
            google: res?.data?.data?.google_analytic_key ? res?.data?.data?.google_analytic_key : ""
          });

          status = res?.data?.status;

          // if (cartdata?.products?.length > 0) {
          //   router.push('/us/checkout/addresslist')
          // } else {
          //   router.push('/us/user/dashboard/');
          // }
        }
      }
    } catch (error) {
      return error.response;
    }
  }



  //profiledata data
  async function ProfileFunction(token) {
    if (token) {
      const data = localStorage.getItem("profileData")
      // setInnerLoader(true);
      if (data) {
        const values = JSON.parse(data);
        setProfileData(values);
        // setProfileData(+values?.user_data?.userdetails?.[0]?.paid_as_rank)
        const RELESE_REPORT = values?.user_data?.userdetails?.[0]?.test_report_release ? values?.user_data?.userdetails?.[0]?.test_report_release : false;
        setReleaseResult(RELESE_REPORT);
        const SUBSCRIBE_ALREADY = values?.user_data?.userdetails?.[0]?.subscribe_order ? values?.user_data?.userdetails?.[0]?.subscribe_order : false;
        const DATA = values?.user_data?.userdetails?.[0]?.one_time_order ? values?.user_data?.userdetails?.[0]?.one_time_order : false
        setOneTimeStatus(DATA)
        setSubscribeAlready(SUBSCRIBE_ALREADY);
        setMiniCartAutoshipCheck(values?.user_data?.userdetails[0]?.is_autoship_user);
        setCustomerCheck(+values?.user_data?.userdetails?.[0]?.qualified_as_rank);
        // setInnerLoader(false);
        setOpenPop(!+values?.user_data?.userdetails[0].privacy_policy)
      }
      else {
        // setInnerLoader(true);
        await api.getMyProfileDetails(token).then(res => {
          if (res?.data?.code === 1) {
            // setInnerLoader(false);
            localStorage.setItem("profileData", JSON.stringify(res?.data))
            setProfileData(res?.data);
            setMiniCartAutoshipCheck(res?.data?.user_data?.userdetails[0]?.is_autoship_user);
            const RELESE_REPORT = res?.data?.user_data?.userdetails?.[0]?.test_report_release ? res?.data?.user_data?.userdetails?.[0]?.test_report_release : false;
            setReleaseResult(RELESE_REPORT);
            const SUBSCRIBE_ALREADY = res?.data?.user_data?.userdetails?.[0]?.subscribe_order ? res?.data?.user_data?.userdetails?.[0]?.subscribe_order : false;
            const DATAONETIME = res?.data?.user_data?.userdetails?.[0]?.one_time_order ? res?.data?.user_data?.userdetails?.[0]?.one_time_order : false;
            setOneTimeStatus(DATAONETIME)
            setSubscribeAlready(SUBSCRIBE_ALREADY);
            setCustomerCheck(+res?.data?.user_data?.userdetails?.[0]?.qualified_as_rank);
            // setInnerLoader(false);
            setOpenPop(!+res?.data?.user_data?.userdetails[0].privacy_policy)

          } else {
            // setInnerLoader(false);
          }
        })
      }
    }
  }

  useEffect(() => {
    if (validReferralCheck === true) {
      if (analyticsCode?.google != "") {
        // ReactGA.initialize(`${analyticsCode?.google}`, { titleCase: false, gaOptions: { name: 'track' }, debug: true });
        // ReactGA.pageview(router.asPath);
        // ReactGA.modalview(router.asPath);
        ReactGA.initialize(analyticsCode?.google);
        ReactGA.send({ hitType: "pageview", page: router.asPath });
      }
    }
  }, [analyticsCode, router.asPath]);

  useEffect(() => {
    const PARENT_REFFERAL = Cookie.get('refferal-code')?.length > 0 ? Cookie.get('refferal-code') : null;
    if (PARENT_REFFERAL) {
      if (localStorage.getItem)
        setRefferalFromUrl(true);
      // subdomainUserCheckFunction(PARENT_REFFERAL);
    }

    const AccessSite = localStorage.getItem('access') ? localStorage.getItem('access') : null;
    setAccessSite(AccessSite);
  }, [router.asPath]);



  useEffect(() => {
    const PARENT_REFFERAL = Cookie.get('refferal-code') ? Cookie.get('refferal-code') : null;
    if (PARENT_REFFERAL) {
      api.GetPersonalLandingPage({ referral_code: PARENT_REFFERAL }).then((res) => {
        if (res?.data?.code === 1) {
          setLandingPageData(res?.data?.Content_data)
        }
        if (res?.data?.code === 0) {
          setPageMsg(res?.data?.message)
        }
      });
    }

  }, [router.asPath]);



  async function clickapi() {
    const headers = { 'Content-Type': 'application/json' };
    if (leadIdData) {
      await axios.post(process.env.clickedapi, { lead_id: leadIdData }, { headers }).then((res) => {
      }).catch((err) => {
        console.log(err)
      });
    }
  }


  useEffect(() => {
    const lead_id = router?.query?.lead_id ? router?.query?.lead_id : null;
    if (lead_id) {
      localStorage.setItem("leadid", lead_id)
      setLeadIddata(lead_id)
      clickapi();
    }

  }, [validReferralCheck, leadIdData, router?.query?.lead_id]);

  useEffect(() => {
    // setisLogin(localStorage.getItem('Login') ? JSON.parse(localStorage.getItem('Login')) : false);
    const formdata = localStorage.getItem('Token') ? localStorage.getItem('Token') : '';
    if (updatecartdata) {
      WishlistAllCart(formdata);
      setupdatecartdata(false)
    }
    ProfileFunction(formdata);
  }, [updatecartdata,]);

  var url = router?.asPath;
  const parts = url.split("/blog/");
  const category_parts = url.split("/category/");
  const [userLoginData, setUserLOginData] = useState();

  useEffect(() => {
    const USERLOGIN = localStorage.getItem('Token') ? localStorage.getItem('Token') : '';
    setUserLOginData(USERLOGIN)
  }, [router, userLoginData])

  const hiddenHeaderUrls = ["/personalized-dna-health-reports/", "/referrersignup/", `${(!userLoginData && "/us/checkout/addresslist/")}`, `${(!userLoginData && "/us/kit_form/?checkout")}`, "/dna-based-nutrition-solutions/", "/dna-based-nutrition-solutions2/", "/personalized-dna-health-reports2/"
  ];
  const isHiddenHeaderUrl = hiddenHeaderUrls?.includes(router?.asPath);

  useEffect(() => {
    setInnerLoader(true)
    const formdata = localStorage.getItem('Token') ? localStorage.getItem('Token') : '';
    setisLoginToken(formdata);
    if (formdata === '' || formdata === undefined) {
      if (Cookie.get('sessionkey')?.length !== 16) {
        Cookie.set('sessionkey', randomstring.generate(16))
      }
    }

    if (code !== undefined) {
      setTimeout(() => {
        if (code !== formdata) {
          localStorage.removeItem('profileData');
          localStorage.removeItem('Token');
        }
        localStorage.removeItem('address_id');
        localStorage.removeItem('Login');

        validateauth(code);
      }, 3000);
    } else if (formdata) {
      validateauth(formdata);
    } else {
      setInnerLoader(false)
    }
  }, [code])

  Router.onRouteChangeStart = () => {
    // setInnerLoader(true)
  };

  Router.onRouteChangeComplete = () => {
    // setInnerLoader(false)
  };

  Router.onRouteChangeError = () => {

  };

  const [previousPath, setpreviousPath] = useState("");
  const validateauth = async (token) => {
    // Clear user-specific data
    if (!kitPopup) {
      setInnerLoader(false);
    }
    setshowloader(false);
    setisLogin(false);
    setisLogout(true);
    setShowProtectedPage(false);

    const TOKEN = token !== undefined ? token : localStorage.getItem('Token');
    await api.LoginCheck(TOKEN).then(res => {
      if (res?.data?.code === 1) {
        if (code) {
          router.push(`/us/user/dashboard/`);
        }
        setisLogin(true);
        localStorage.setItem('Login', true);
        localStorage.setItem('Token', TOKEN);
        ProfileFunction(TOKEN);
        if (!kitPopup) {
          setTimeout(() => {
            setInnerLoader(false);
            setshowloader(false);
          }, 7000);
        }
        setShowProtectedPage(true);
      }
      else if (res?.data?.code === 0) {
        // Remove user-specific data from local storage
        localStorage.removeItem('Token');
        localStorage.removeItem('address_id');
        localStorage.removeItem('Login');

        // Redirect to login page
        setpreviousPath(router.asPath);
        setInnerLoader(false);
        setshowloader(false);
        setisLogin(false);
        setisLogout(true);
        setShowProtectedPage(false);

        if (router.asPath?.includes('/user/')) {
          router.push('/us/login?redirectTo=' + router.asPath?.replace('[page]', 'us'));
        } else {
          router.push('/us/login');
        }
      }
    });
  };

  useEffect(() => {
    if (router?.query?.n === "news_letter") {
      document.body.classList.add('professional_retailer_form_popup');
      setOpenForm(true)
    }
    else {
      setOpenForm(false)
      document.body.classList.remove('professional_retailer_form_popup');
    }
  }, [router])

  useEffect(() => {
    if (router?.query) {
      setRouterProductQuery(router?.query?.product_id);
      setRouterKitQuery(router?.query?.kitProductID)
      const PARENT_REFFERAL = Cookie.get('refferal-code')?.length > 0 ? Cookie.get('refferal-code') : null;
      api.getAllProduct({ refferalCode: PARENT_REFFERAL, slug: 'us' }).then(res => {
        if (res?.status === 200) {
          const productToDisplay = res?.data?.products?.find((product) => product?.id === (router?.query?.product_id || routerProductQuery));
          const getKitId = res?.data?.products?.find((product) => product?.id === (router?.query?.kitProductID || routerKitQuery));
          localStorage.getItem("leadid") ? localStorage.getItem("leadid") : null
          const USER_REFERRAL = router?.query?.ref;
          if (USER_REFERRAL !== undefined) {
            Cookie.set('refferal-code', USER_REFERRAL, { expires: 90 });
          }
          // localStorage.setItem("alternateid", router?.query?.ref)
          if (getKitId) {
            const formData = {
              product_id: getKitId?.id,
              variant_id: null,
              token: isLoginToken,
              quantity: 1,
              is_autoship: false
            };
            if (getKitId) {
              api.addToCart(formData).then((res) => {
                if (res) {
                  setInnerLoader(false)
                  router?.push("/us/checkout/addresslist/")
                }
              }).then(
                getMiniCartProduct()
              )
            }
            else {
              setInnerLoader(false)
              router?.push('/')
            }
          }
          if (productToDisplay) {
            const formData = {
              product_id: productToDisplay?.id,
              variant_id: null,
              token: isLoginToken,
              quantity: 1,
              is_autoship: true
            };

            if (productToDisplay) {
              api.addToCart(formData).then((res) => {
                if (res) {
                  setInnerLoader(false)
                  router?.push("/us/checkout/addresslist/")
                }
              }).then(
                getMiniCartProduct()
              )
            }
            else {
              setInnerLoader(false)
              router?.push('/')
            }
          }


        }
      })
    }
  }, [router])

  const urls = router?.asPath;

  const PARENT_REFFERAL = Cookie.get('refferal-code')?.length > 0 ? Cookie.get('refferal-code') : null;

  const partss = urls.split("/blog/");

  const slug = partss[1];

  if (partss[0] === "/" + PARENT_REFFERAL) {
    // router?.push('/blog/from-a-d1-athlete-to-snip-nutrigenomics-powerhouse/')
    router.push(`/blog/${slug}`);
  };

  //************************************23&ME Flow*******************************/
  useEffect(() => {
    const DNA_SURVEY_DATA = localStorage?.getItem("profileData") && JSON.parse(localStorage?.getItem("profileData"));
    setUserReportsCheck(DNA_SURVEY_DATA)
    const TOKEN = localStorage.getItem("Token");
    if (TOKEN && router?.asPath === "/us/user/dashboard/") {
      setKitPopup(JSON.parse(localStorage?.getItem("profileData"))?.user_data?.userdetails?.[0]?.dna_report_release === false ? true : false)
    }
    if (TOKEN) {
      api.autoshipOrderHistory(TOKEN, 1).then(res => {
        if (res?.status === 200) {
          setOrderData(res?.data?.data)
        }
      })
    }
  }, [router])

  const [AlreadySubscriptionPopup, setAlreadySubscriptionPopup] = useState(false);
  const [alreadySubscription, setAlreadySubscription] = useState(false);
  const [selectCheck, setSelectCheck] = useState(false);
  const [openPopup, setOpenPopup] = useState(false)
  const [openPopupUnCheck, setOpenPopupUnCheck] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState({});
  const [productData, setProductData] = useState()
  const [cartNotify, setCartNotify] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const CHECK_VALUE = _.filter(_.values(selectedChoice), { text: 'N/A' });

  async function ProfileApiCall() {
    api.getMyProfileDetails(localStorage.getItem("Token")).then(res => {
      if (res?.data?.code === 1) {
        localStorage.setItem("profileData", JSON.stringify(res?.data))
        // setProfilesData(res?.data);
      }
      else {
        setInnerLoader(false)
      }
    })
  }

  function handleOpenBox(selectCheck) {
    setInnerLoader(true)
    api.reports_generate().then((res) => {
      if (res?.data?.code === 1) {
        localStorage.removeItem("profileData");
        ProfileApiCall();
        setInnerLoader(false)
        setKitPopup(false);
        if (selectCheck) {
          setOpenPopup(true)
        }
        else if (!selectCheck && alreadySubscription && CHECK_VALUE?.length > 0) {
          setAlreadySubscriptionPopup(true)
        }
        else if (!selectCheck && !alreadySubscription && CHECK_VALUE?.length > 0) {
          setOpenPopupUnCheck(true)
        }
        else {
          setOpenPopupUnCheck(true)
        }
      }
      else if (res?.data?.code === 0) {
        setInnerLoader(false)
      }
    })
  };

  useEffect(() => {
    const category = 3;
    _getAllProduct(false, category).then((res) => {
      let OBJECT = res?.find(obj => obj?.category_id === 3);
      setProductData(OBJECT)
    })
  }, []);

  function handleDiscount() {
    Cookies.set('kitProduct', productData?.id, new Date(new Date().getTime() + 15 * 60 * 1000));
    localStorage.setItem("From_Survey_Flow", true);
    setKitPopup(false)
    const kit_Product = Cookies.get("kitProduct")
    if (kit_Product) {
      addToCartMethod(
        {
          id: productData?.id,
          quantity: 1,
          is_autoship: false,
        },
        setshowminicart,
        setCartNotify,
        cartdata?.products,
        setCartData,
        false,
        setErrorMsg,
      )
      router?.push("/us/cart/viewCart/")
      setKitPopup(false);
      setOpenPopupUnCheck(false)
    }
  };
  //****************************************************************23&ME FLOW END*********************************/

  function handleClick() {
    setOpenPopup(false)
    localStorage.removeItem("profileData")
    setInnerLoader(true)
    api.getMyProfileDetails(localStorage.getItem("Token")).then(res => {
      if (res?.data?.code === 1) {
        localStorage.setItem("profileData", JSON.stringify(res?.data))
        if (localStorage.getItem("profileData")) {
          router.push("/us/cart/viewCart/?formula")
        }
      }
      else {
        setInnerLoader(false)
      }
    })
    Cookies.set('from-dna-popup', true, new Date(new Date().getTime() + 15 * 60 * 1000));
  };

  return (
    <>
      <Head>
        <title>SNiP Nutrigenomics</title>
        <script
          src={`https://maps.googleapis.com/maps/api/js?key=AIzaSyDy_EUlVOYeUKldEmPfV9e5tT2I_RmJ5o0&libraries=places`}
          strategy="beforeInteractive"
        />
        <meta property="og:title" content="DNA-Tailored Nutrition for Whole Body Health" />
        <meta charSet="utf-8" />
        {/*  <meta name="viewport" content="width=device-width, initial-scale=1" />*/}
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=1.0, minimum-scale=1.0, maximum-scale=1.0" />
        <base href="/" />
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        {/* <script async src={`https://www.googletagmanager.com/gtag/js?id=${analyticsCode?.google}`}></script> */}
        <script></script>

        <script dangerouslySetInnerHTML={{
          __html: `
          (function(){ var s = document.createElement('script'); var h = document.querySelector('head') 
          || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true;
           s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, 
           disableBgProcess : false, language : 'en', position : 'left', leadColor : '#5d3486', triggerColor : '#5d3486', 
           triggerRadius : '50%', triggerPositionX : 'left', triggerPositionY : 'bottom', triggerIcon : 'people',
            triggerSize : 'small', triggerOffsetX : 10, triggerOffsetY : 10, mobile : { triggerSize : 'small', triggerPositionX : 'left',
             triggerPositionY : 'bottom', triggerOffsetX : 10, triggerOffsetY : 0, triggerRadius : '50%' } }); }; h.appendChild(s); })(); 
                       `
        }}
        />
        <>
          <script
            async
            src={`https://www.facebook.com/tr?id=1064243681271681&ev=PageView&noscript=1`}
          />
          <script dangerouslySetInnerHTML={{
            __html: `
          !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '1064243681271681');
          fbq('track', 'PageView');`
          }}
          />

        </>

        <>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-9BT7P4QB5H"></script>
          <script dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-9BT7P4QB5H');`
          }}
          />
        </>

        {/* <!-- Facebook Pixel Code --> */}
        {
          //   (!validReferralCheck && analyticsCode?.facebook) &&
          //   <>
          //     <script dangerouslySetInnerHTML={{
          //       __html: `
          //   !function(f,b,e,v,n,t,s)
          // {
          //     if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
          //   n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          // };
          // if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          // n.queue=[];t=b.createElement(e);t.async=!0;
          // t.src=v;s=b.getElementsByTagName(e)[0];
          //     s.parentNode.insertBefore(t,s)}(window,document,'script',
          // 'https://connect.facebook.com/en_US/fbevents.js');
          // fbq('init', '${analyticsCode?.facebook}');
          // fbq('track', 'PageView');`
          //     }} />
          //     {/* // */}
          //     <noscript>
          //       <img height="1" width="1" src={`https://www.facebook.com/tr?id=${analyticsCode?.facebook}&ev=PageView&noscript=1`} />
          //     </noscript>
          //   </>
        }

      </Head>
      {!isHiddenHeaderUrl &&
        <NavBar
          setoldpath={setoldpath}
          handleLogout={handleLogout}
          innerLoader={innerLoader}
          subDomain={subDomain}
          cartdata={cartdata}
          setInnerLoader={setInnerLoader}
          isLogin={isLogin}
          isLoginToken={isLoginToken}
          notificationSection={{ usernotifications, setUsernotification }}
          setisLogin={setisLogin}
          counts={counts}
          correctRefferal={correctRefferal}
          setcounts={setcounts}
          updatecartdata={setupdatecartdata}
          profileData={profileData}
        />
      }

      {/* <ParallaxProvider> */}
      <div className="main_div">
        <Component
          {...{
            handleLogout, minDate, setMinDate, setReleaseResult, subscribeAlready, removeStorages, reviewshow, setReviewShow, ProfileFunction, releaseResult, refferalFromUrl, dataReferer,
            setDataRefer, minCartAutoshipCheck, subdomainUserCheckFunction, subDomain, getMiniCartProduct, innerLoader, setActiveStep, activeStep,
            setInnerLoader, setProfileData, setSubDomain, validReferralCheck, setValidReferralCheck, profileData, showloader, setOpenForm, openForm,
            getDetails, LoginSubmit, loginError, customerCheck, setCustomerCheck, validateauth, setshowloader, setcounts, counts, setshowminicart,
            setoldpath, oldpath, setisLogin, cartdata, setCartData, addNotify, setAddNotify, isLogin, smartShipCheck, setSmartShipCheck, setupdatecartdata,
            showprotectedpage, promoterPackAvailability, setdiscount_amount, discount_amount1, setSmartShipOnCart, smartShipOnCart, coupon, setcoupon, amountCalculations,
            setAmountCalculations, landingPageData, pageMsg, referralName, setReferralName, referralCompanyName, setReferralCompanyName, setLeadIddata,
            AlreadySubscriptionPopup, setAlreadySubscriptionPopup, alreadySubscription, setAlreadySubscription, subscribeAlready, selectCheck, setSelectCheck,
            openPopup, setOpenPopup, openPopupUnCheck, setOpenPopupUnCheck, selectedChoice, setSelectedChoice, correctRefferal, setCorrectRefferal,
            oneTimeStatus, setOneTimeStatus, ordersData, productQtyError, setProductQtyError

          }}
          signUpRefferal={{ correctRefferal, setCorrectRefferal }}
          {...pageProps}
        />

      </div>
      {/* </ParallaxProvider> */}

      <Minicart
        subscribeAlready={subscribeAlready}
        cartdata={cartdata}
        smartShipOnCart={smartShipOnCart}
        setCartData={setCartData}
        getMiniCartProduct={getMiniCartProduct}
        setcounts={setcounts}
        counts={counts}
        minCartAutoshipCheck={minCartAutoshipCheck}
        showminicart={showminicart}
        setshowloader={setshowloader}
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        setInnerLoader={setInnerLoader}
        setshowminicart={setshowminicart}
        smartShipCheck={smartShipCheck}
        isLogin={isLogin}
        setdiscount_amount={setdiscount_amount}
        discount_amount1={discount_amount1}
        coupon={coupon}
        setcoupon={setcoupon}
        amountCalculations={amountCalculations}
        setAmountCalculations={setAmountCalculations}
        productQtyError={productQtyError}
        setProductQtyError={setProductQtyError}

      />

      {
        !isHiddenHeaderUrl &&
        <Footer showloader={showloader}
          setshowloader={setshowloader}
          innerLoader={innerLoader}
          setActiveStep={setActiveStep}
          subDomain={subDomain}
          isLoginToken={isLoginToken}
          setInnerLoader={setInnerLoader}
          handleLogout={handleLogout}
          isLogin={isLogin}
          setOpenForm={setOpenForm}
          openForm={openForm}
          notificationSection={{ usernotifications, setUsernotification }}
          counts={counts}
          profileData={profileData}
        />
      }

      <DialogPrivacyPolicy openPop={openPop} setOpenPop={setOpenPop} />

      <DialogComponent opend={openPopup} handleClose={() => setOpenPopup(true)} classFor="kitPopup-data" section='supplement_buy'>
        <div className='code-complex'>
          <p className='personalized nutritional'> Thank you! Your <b>Premium C.O.D.E. Report,</b> personalized nutritional formula's <b>Supplement Facts panel</b> and <b>Summary DNA Health Report </b> are now unlocked and will be available in your SNiP account in the next few minutes.
            You will now be directed to select capsules or powder for your CODE Complex™ DNA-personalized formula and complete your supplement order.</p>
          <div className='cstm-dna_info'>
            <button className='choose-complex' onClick={handleClick}> Choose my formula (capsules or raw powder) and complete my supplement order now</button>
          </div>
          <div className="dna_info-btn">
            <p className='personalized nutritional-question'>Questions? We’re here to help!</p>
            <p className='personalized nutritional-question'>Email us at <LinkAnchor href="mailto:support@snipnutrition.com">support@snipnutrition.com</LinkAnchor> </p>
          </div>
        </div>
      </DialogComponent>
      <DialogComponent opend={openPopupUnCheck} handleClose={() => setOpenPopupUnCheck(true)} classFor="kitPopup-data" section='supplement_buy'>
        <div className='code-complex'>
          <p className='personalized nutritional'>Thank you! Your <b>Premium C.O.D.E. Report,</b> personalized nutritional formula's <b>Supplement Facts panel</b> and <b>Summary DNA Health Report </b> are now unlocked and will be available in your SNiP account in the next few minutes.</p>
          <div className='cstm-dna_info'>
            <button className='choose-complex' onClick={() => { router.push("/us/user/dashboard"), localStorage.setItem("From_Survey_Flow", true), setOpenPopupUnCheck(false), setOpenPopup(false), setKitPopup(false) }}>Go to my SNiP account</button>
          </div>

          <div className="dna_info-btn">
            <p className='personalized nutritional-question'>Questions? We’re here to help!</p>
            <p className='personalized nutritional-question'>  Email us at <LinkAnchor href="mailto:support@snipnutrition.com">support@snipnutrition.com</LinkAnchor> </p>
          </div>

        </div>
        {innerLoader === true && (
          <div className="flexbox">
            <div className="">
              <div className="bt-spinner"></div>
            </div>
          </div>
        )
        }
      </DialogComponent>
      <DialogComponent opend={kitPopup} handleClose={() => setKitPopup(false)} classFor="kitPopup-data kitPopup-content" >
        <div className='code-complex'>
          <p className='personalized nutritional kit-bolder'>We noticed you marked some SNPs "Not Genotyped/Not Available."</p>
          <p className='personalized nutritional kit-bolder'>Here's what you need to know:</p>
          <p className='personalized nutritional kit'>Your supplement will be tailored to the DNA data you provide. Missing SNP
            data means those specific ingredients won’t be in your formula.</p>
          <p className='personalized nutritional kit'>Want full coverage? Test with SNiP Nutrigenomics today for $297. (We’ll
            apply the amount you’ve already paid). And, when you test with SNiP, you’ll get
            not only your Summary DNA Health Report and unlock your personalized formula, you’ll
            also receive 11 Personalized DNA Health Reports including: </p>
          <div className='listing array-data-content'>
            {ARRAY_DATA?.map((items) => {
              return (
                <p className='personalized nutritional listing_class'>{items}</p>
              )
            })}
          </div>

          <p className='personalized nutritional'>Choose either option, and you can subscribe to your DNA-customized
            supplement now, saving up to $30/month. </p>
          <div className='cstm-dna_info'>
            <button className='choose-complex' onClick={() => handleOpenBox(selectCheck)} > Use the DNA data I provided to unlock my personalized formula </button>
          </div>
          <div className='cstm-dna_info'>
            <button className='choose-complex' onClick={() => handleDiscount()} >Apply my payment paid towards a SNiP DNA Home Test Kit + Health Reports </button>
          </div>
          <p className="note-us" style={{ textAlign: "center", fontStyle: "italic", fontSize: "16px", fontWeight: "700" }}>NOTE: Available in the US & Canada only.</p>
          <div className="dna_info-btn">
            <p className='personalized nutritional-question'>Questions? We’re here to help!</p>
            <p className='personalized nutritional-question'>  Email us at <LinkAnchor href="mailto:support@snipnutrition.com">support@snipnutrition.com</LinkAnchor> </p>
          </div>
        </div>
        {innerLoader === true && (
          <div className="flexbox">
            <div className="">
              <div className="bt-spinner"></div>
            </div>
          </div>
        )
        }
      </DialogComponent>
      <DialogComponent opend={AlreadySubscriptionPopup} handleClose={() => setAlreadySubscriptionPopup(false)} classFor="kitPopup-data kitPopup-content" >
        <div className='code-complex'>
          <p className='personalized nutritional'>Success! Your {/* CODE Complex™  Premium  */} CODE Complex™
            DNA-personalized nutrition is now in the works.
            Please allow up to three weeks for your supplement to arrive. Once shipped,
            a tracking number will be available under "My Orders."</p>
          <p className='personalized nutritional'>Visit your <a onClick={() => {
            router?.push("/us/user/dashboard"),
              setAlreadySubscriptionPopup(false)
          }} style={{ cursor: 'pointer' }}>account</a> now to view your order details and access your Premium C.O.D.E. Report, personalized
            Supplement Facts Panel and Summary DNA Health Report.
          </p>
          <div className='cstm-dna_info'>
            <button className='choose-complex' onClick={() => { router?.push("/us/user/dashboard"), setAlreadySubscriptionPopup(false), setOpenPopupUnCheck(false), setOpenPopup(false), setKitPopup(false), setKitPopup(false) }}>Go to my SNiP account now</button>
          </div>
          <div className="dna_info-btn">
            <p className='personalized nutritional-question' style={{ fontSize: 18 }}>Questions? We’re here to help!</p>
            <p className='personalized nutritional-question' style={{ fontSize: 18 }}>  Email us at <LinkAnchor href="mailto:support@snipnutrition.com">support@snipnutrition.com</LinkAnchor> </p>
          </div>

        </div>
        {innerLoader === true && (
          <div className="flexbox">
            <div className="">
              <div className="bt-spinner"></div>
            </div>
          </div>
        )
        }
      </DialogComponent>
    </>
  )
}

export default MyApp;